import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SiteLayout from '../components/SiteLayout';

const ContactWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Contact = ({ }) => {
  const LAYOUT_KEY = 'CONTACT';
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <ContactWrapper>Contact</ContactWrapper>
    </SiteLayout>
  );
};

Contact.propTypes = {};

export default Contact;
